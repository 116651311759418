<template>
  <div class="transfer-container">
    <div class="transfer-head">
      <div>付款金额</div>
      <div class="rem40 lh56 mt12">¥{{ info.repayAmount }}</div>
      <van-divider />
      <div class="rem26 lh18 c-8E929B">
        本金¥{{ info.prcp }}+利息¥{{ info.normInt
        }}<span v-show="info.fee">+手续费¥{{ info.fee }}</span
        ><span v-show="info.preFee">+提前付款手续费¥{{ info.preFee }}</span
        ><span v-show="info.odInt">+罚息¥{{ info.odInt }}</span>
      </div>
      <div v-show="remitAmt" class="lh24 c-FF6646">信用减免红包（当日有效）{{ remitAmt }}元</div>
    </div>

    <div class="transfer-body">
      <div class="notice-top"></div>
      <div class="notice-info">
        <van-icon name="warning-o" class="icon-gth" /><span
          >请复制以下账户信息进行转账付款，可通过网银、手机银行、银行柜台、ATM等方式转账，请务必备融资人身份信息</span
        >
      </div>
      <div class="m25">
        <div class="rem30 lh21">
          <span class="c-FF6646">*</span> <span class="c-999999">公司名称</span>
        </div>
        <div class="flex just-between align-center">
          <span class="rem32 lh24 w80">{{ name }}</span
          ><span
            ref="copy"
            :data-clipboard-text="name"
            @click="copy"
            id="copy_text"
            class="copy-btn"
            >复制</span
          >
        </div>
      </div>

      <div class="m25">
        <div class="rem30 lh21">
          <span class="c-FF6646">*</span> <span class="c-999999">开户行</span>
        </div>
        <div class="flex just-between align-center">
          <span class="rem32 lh24 w80">{{ bankName }}</span
          ><span
            ref="copy"
            :data-clipboard-text="bankName"
            @click="copy"
            id="copy_text"
            class="copy-btn"
            >复制</span
          >
        </div>
      </div>

      <div class="m25">
        <div class="rem30 lh21">
          <span class="c-FF6646">*</span> <span class="c-999999">账号</span>
        </div>
        <div class="flex just-between align-center">
          <span class="rem32 lh24 w80">{{ account }}</span
          ><span
            ref="copy"
            :data-clipboard-text="account"
            @click="copy"
            id="copy_text"
            class="copy-btn"
            >复制</span
          >
        </div>
      </div>

      <div class="m25">
        <div class="rem30 lh21">
          <span class="c-FF6646">*</span> <span class="c-999999">打款备注(身份证号)</span>
        </div>
        <div class="flex just-between align-center padding-bottom20">
          <span class="rem32 lh24 w80">{{ idNo }}</span
          ><span
            ref="copy"
            :data-clipboard-text="idNo"
            @click="copy"
            id="copy_text"
            class="copy-btn"
            >复制</span
          >
        </div>
      </div>
    </div>

    <!-- 温馨提示 -->
    <div class="warm-prompt">
      <div class="flex align-center">
        <span class="icon-blue"></span><span class="rem30 lh21">温馨提示</span>
      </div>
      <div class="mt8 rem26 lh24 c-999999">
        1.转账时间：每天9:00-23:00 <br />
        2.建议您使用平台绑定银行卡转账。<br />
        3.转账付款需人工进行操作，建议您提前转账。避免逾期对您的信用记录产生影响。<br />
        4.转账完成后，请即时致电联系客服40069-95568转2，完成付款入账，避免产生付款逾期<br />
      </div>
    </div>

    <!-- 按钮 -->
    <div class="transfer-btn">
      <van-button round block type="info" native-type="submit" @click="completeBack">
        <span class="rem34">完成转账并返回</span>
      </van-button>
      <van-button round block plain color="#4D7BFE" class="btn" @click="completeBack">
        <span class="rem34">返回</span>
      </van-button>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import Vue from 'vue'
import { Divider, NoticeBar, Icon, Button, Toast } from 'vant'

import { getTranRepayInfoApi } from '@/apis/repayment'
import { Decrypt } from '@/utils/crypto'

Vue.use(Divider)
Vue.use(NoticeBar)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Toast)

export default {
  data() {
    return {
      name: '',
      account: '',
      idNo: '',
      bankName: '',
      loanNo: '',
      info: {},
      remitAmt: 0, // 减免金额
    }
  },
  created() {
    this.loanNo = this.$store.state.repayment.loan.loanNo
    this.getTranRepayInfo()
    this.info = this.$store.state.repayment.payInfo
    this.remitAmt = Number(this.$route.query.remitAmt)
  },
  methods: {
    copy() {
      // const _this = this
      const clipboard = new Clipboard('.copy-btn')

      clipboard.on('success', () => {
        Toast({
          message: '复制成功',
          position: 'top',
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        // eslint-disable-next-line no-undef
        Message({
          message: '该浏览器不支持自动复制',
          type: 'warning',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    // 获取转账付款信息
    getTranRepayInfo() {
      // 有数据的借据号： LO409831661333868545
      getTranRepayInfoApi(this.loanNo).then((res) => {
        if (res.code === 200) {
          console.log(res)
          this.name = res.data.name
          this.account = res.data.account
          this.bankName = res.data.bankName
          this.idNo = Decrypt(res.data.idNo)
        }
      })
    },

    // 完成转账并返回
    completeBack() {
      window.history.go(-3)
    },
  },
}
</script>

<style lang="less" scoped>
.transfer-container {
  width: 100%;
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding-bottom: 0.4rem /* 20/50 */;
  .transfer-head {
    background-color: #fff;
    padding: 0.22rem /* 11/50 */ 0.3rem /* 15/50 */;
  }
  .transfer-body {
    box-sizing: border-box;
    margin: 0.3rem /* 15/50 */;
    background-color: #fff;
    .notice-info {
      background-color: #fff7f7;
      color: #ff6646;
      display: flex;
      font-size: 0.24rem /* 12/50 */;
      line-height: 0.36rem /* 18/50 */;
      margin: 0 0.1rem /* 5/50 */;
    }
    .icon-gth {
      line-height: 0.36rem /* 18/50 */;
      margin: 0 0.1rem /* 5/50 */;
    }
    .notice-top {
      height: 0.1rem /* 5/50 */;
    }

    .copy-btn {
      display: inline-block;
      background-color: #f1f5ff;
      color: #4d7bfe;
      font-size: 0.26rem /* 13/50 */;
      line-height: 0.48rem /* 24/50 */;
      padding: 0.08rem /* 4/50 */ 0.2rem /* 10/50 */;
      border-radius: 0.06rem /* 3/50 */;
      width: 0.92rem /* 46/50 */;
      box-sizing: border-box;
      text-align: center;
    }
  }
  .warm-prompt {
    margin: 0.4rem /* 20/50 */ 0.3rem /* 15/50 */;

    .icon-blue {
      display: inline-block;
      width: 0.06rem /* 3/50 */;
      background-color: #4d7bfe;
      margin: 0.06rem /* 3/50 */ 0.1rem /* 5/50 */;
      line-height: 0.42rem /* 21/50 */;
      height: 0.3rem /* 15/50 */;
    }
  }

  .transfer-btn {
    margin: 0.5rem /* 25/50 */;
    .btn {
      margin-top: 0.4rem /* 20/50 */;
    }
  }
}

/deep/ .van-divider {
  margin: 0.18rem /* 9/50 */;
}
</style>
